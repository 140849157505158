<gw-chkout-visa-layout [bg]="country_meta.background_img || default_bg"
  [country]="country">
  @if (visas.service_unavailable) {
    <section class="gw-chkout-country-info__item">
      <gw-chkout-applicant-info type="search"
        [citizenship]="searchForm.get('info').get('citizenship')"
        [residency]="searchForm.get('info').get('residency')"
        [country]="visaForm.controls.country"
        (onInfoUpdated)="getUpdatedProducts()">
      </gw-chkout-applicant-info>
    </section>
  }
  <h2>Expedited {{ country.label }} Visa Services</h2>
  @if ((!visas.service_unavailable || visas.service_unavailable.unavailable_reason == 'visa_not_required') && country_meta.top_body_content) {
    <p
      [innerHtml]="replaceCompany(country_meta.top_body_content)">
    </p>
  }
  @if ((!visas.service_unavailable || visas.service_unavailable.unavailable_reason == 'visa_not_required') && !country_meta.top_body_content) {
    <p>
      Applicant does not require a visa for traveling to {{ country.label }}. Visit the consulate's website to obtain more information on visa requirements.
    </p>
  }
  @if (!visas.service_unavailable && selected_type) {
    <section>
      <h3>Select {{ country.label }} Visa Type</h3>
      <ul class="gw-chkout-country-list">
        @for (type of types; track type) {
          <li
            (click)="onSelectVisaType(type)"
            class="gw-chkout-country-list__item"
            [class.gw-chkout-country-list__item--active]="selected_type === type">
            {{ visa_types[type] }}
          </li>
        }
      </ul>
      <mat-form-field class="gw-chkout-field gw-chkout-traveler__select gw-chkout-country-type">
        <mat-label>Entry Type</mat-label>
        <mat-select [(ngModel)]="selected_type"
          (selectionChange)="onSelectVisaType(selected_type)">
          @for (type of types; track type) {
            <mat-option
              [value]="type">
              {{ visa_types[type] }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <form [formGroup]="visaForm"
        class="gw-chkout-country-info">
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a dolor vitae orci egestas congue quis ac est. Sed sollicitudin magna enim, sed tristique dui tempor non. </p> -->
        <section class="gw-chkout-country-info__item">
          <gw-chkout-applicant-info
            [citizenship]="searchForm.get('info').get('citizenship')"
            [residency]="searchForm.get('info').get('residency')"
            [country]="visaForm.controls.country"
            (onInfoUpdated)="getUpdatedProducts()">
          </gw-chkout-applicant-info>
        </section>
        <section class="gw-chkout-country-info__item">
          <h4>Select Visa Entry Type</h4>
          <mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-field--border gw-chkout-traveler__select"
            [hideRequiredMarker]="true">
            <mat-label>Entry Type</mat-label>
            <mat-select formControlName="product_uuid">
              @for (entry of visas[selected_type]; track entry) {
                <mat-option
                  [value]="entry.uuid">
                  {{ entries[entry.entry_type] }}/{{ beautifyPeriod(entry.max_validity) }} Validity/{{ beautifyPeriod(entry.max_stay) }} Stay
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </section>
        <section class="gw-chkout-country-info__item">
          <h4>Select Expediting Options</h4>
          <mat-radio-group formControlName="option_uuid"
            color="primary"
            class="gw-checkout-radio">
            @for (service of getServices(); track service) {
              <mat-radio-button class="gw-chkout-radio__item gw-chkout-radio__item--center"
                [value]="service.uuid">
                <gw-chkout-visa-option [service]="service">
                </gw-chkout-visa-option>
              </mat-radio-button>
            }
          </mat-radio-group>
        </section>
        @if (!duplicatedProduct) {
          <div class="gw-chkout-country-cta">
            <button mat-flat-button
              color="primary"
              (click)="submitForm()"
              class="gw-chkout-main-btn gw-chkout-main-btn--flat gw-chkout-main-btn--notransform">
              Add Selected Visa to Cart
            </button>
            @if (cartIsActive()) {
              <button mat-flat-button
                class="gw-chkout-small-btn gw-chkout-view-card"
                [routerLink]="'/step-1'">
                View Cart
              </button>
            }
          </div>
        } @else {
          <div class="gw-chkout-country-cta">
            <div class="gw-chkout__cart-message">
              <span>
                <i>Visa already in the cart...</i>
              </span>
            </div>
            <button mat-flat-button
              color="primary"
              class="gw-chkout-main-btn gw-chkout-main-btn--flat gw-chkout-main-btn--notransform"
              [routerLink]="'/step-1'">
              Proceed to the Cart
            </button>
          </div>
        }
        <section class="gw-chkout-country__reqs">
          <h4>{{ country.label }} {{ visa_types[selected_type] }} Requirements</h4>
          <mat-accordion>
            @for (requirement of visas[selected_type][0].requirements; track requirement; let req_index = $index) {
              <mat-expansion-panel #expansionPanel hideToggle disabled
                class="gw-chkout-country-req">
                <mat-expansion-panel-header collapsedHeight="54px" expandedHeight="54px"
                  class="gw-chkout-country-req__header">
                  <mat-panel-title class="gw-chkout-country-req__title">
                    {{req_index + 1}}. {{ requirement.display_label }}
                    <!-- @if (!expansionPanel.expanded) {
                      <mat-icon svgIcon="arrow_right"></mat-icon>
                    } @else {
                      <mat-icon svgIcon="arrow_down"></mat-icon>
                    } -->
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHTML]="replaceEmail(requirement.site_content)"></p>
              </mat-expansion-panel>
            }
          </mat-accordion>
        </section>
        @if (visas[selected_type][0].notes.length > 0) {
          <section class="gw-chkout-country__reqs">
            <h4>{{ country.label }} {{ visa_types[selected_type] }} Notes</h4>
            <mat-accordion>
              @for (note of visas[selected_type][0].notes; track note; let req_index = $index) {
                <mat-expansion-panel #expansionPanel
                  class="gw-chkout-country-req">
                  <mat-expansion-panel-header collapsedHeight="54px" expandedHeight="54px"
                    class="gw-chkout-country-req__header">
                    <mat-panel-title class="gw-chkout-country-req__title">
                      {{ note.label }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p [innerHTML]="replaceEmail(note.note)"></p>
                </mat-expansion-panel>
              }
            </mat-accordion>
          </section>
        }
      </form>
    </section>
  }
  @if (visas.service_unavailable) {
    <section>
      @if (visas.service_unavailable.unavailable_tips) {
        <div>
          <h4>Travel Tips</h4>
          <p [innerHtml]="visas.service_unavailable.unavailable_tips"></p>
        </div>
      }
      <div class="gw-chkout-country__note">
        <h3>A valid passport is required for travel</h3>
        <div class="gw-chkout-country__passport-reqs">
          <ul>
            <li>Your passport must be in good condition.</li>
            <li>It cannot be expiring 6 months beyond your intended stay in the country.</li>
            <li>You are required to have at least two blank visa pages left in your passport (The last two pages in your passport are not visa pages, and do not count).</li>
          </ul>
        </div>
      </div>
      <div>
        <p style="text-align: center;font-weight: 700">
          If your passport does not meet the above criteria, we can help expedite it!
        </p>
        <div class="gw-chkout-country-cta">
          <button mat-flat-button
            class="gw-chkout-main-btn"
            routerLink="/">
            Expedite your passport
          </button>
        </div>
      </div>
    </section>
  }
</gw-chkout-visa-layout>
