<header class="gw-chkout-heading gw-chkout-heading--{{variation()}}"
	[style]="variation() === 3 && bg() ? 'background-image: url(' + bg() + ')' : ''">
	<h1 [innerHtml]="heading()" 
		class="gw-chkout-heading__title"></h1>
	<p [innerHtml]="description()" 
		class="gw-chkout-heading__description"></p>

	@if (variation() === 4) {
		<img class="gw-chkout-heading__img"
			alt="background"
			[src]="bg()" />
		<div class="gw-chkout-heading__cards">
			<gw-chkout-card>
				<div class="gw-chkout-heading__card">
					<h2>{{ passportObj()?.heading }}</h2>
					<p>
						{{passportObj()?.description}}
					</p>
					<a mat-flat-button
						color="primary"
						class="gw-chkout-main-btn"
						[routerLink]="['/step-1']">
						{{ passportObj()?.cta_label }}
					</a>
				</div>
			</gw-chkout-card>
			<gw-chkout-card>
				<div class="gw-chkout-heading__card">
					<h2>{{ visaObj()?.heading }}</h2>
					<p>
						{{visaObj()?.description}}
					</p>
					<a mat-flat-button
						color="primary"
						class="gw-chkout-main-btn"
						[routerLink]="['/visas']">
						{{ visaObj()?.cta_label }}
					</a>
				</div>
			</gw-chkout-card>
		</div>
	} @else {
		@if (!hide_main_cta() && type !== 'visa') {
			<a mat-flat-button
				color="primary"
				id="btn-start-app"
				class="gw-chkout-main-btn"
				[disabled]="!products"
				(click)="onStartApplication()">
				{{ cta_label() }}
			</a>
		}

		@if (variation() === 3 && type === 'visa') {
			<gw-chkout-applicant-info type="heading"
				[citizenship]="applicantForm.controls.citizenship"
				[residency]="applicantForm.controls.residency"
				[country]="applicantForm.controls.country">
			</gw-chkout-applicant-info>
		}

		@if (subDescription) {
			<p [innerHtml]="subDescription()"
				class="gw-chkout-heading__sub">
			</p>
		}
	}
	<!-- <label for="btn-start-app">Your departure date must be later than: {{ departureDate | date:'MM/dd/yyyy'}}</label> -->
</header>
