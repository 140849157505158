import { Component } from '@angular/core'
import { MatGridListModule } from '@angular/material/grid-list'
import { ActivatedRoute } from '@angular/router'
import { TrustpilotModule } from 'src/app/components/trustpilot/trustpilot.module'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss'],
	standalone: true,
	imports: [
		MatGridListModule,
		TrustpilotModule
	]
})

export class TermsComponent {
	public type: string
	public titles: {[key: string]: string} = {
		terms: 'Terms of Use',
		privacy: 'Privacy Policy',
		refund: 'Refund Policy'
	}
	public trustpilot = environment.tags ? environment.tags.trustpilot : null
	public website_url: string = 'https://www.rushmypassport.com/'
	public website: string = 'www.rushmypassport.com'
	public name: string = 'RushMyPassport.com'
	public email: string = 'info@rushmypassport.com'
	public domain: string = environment.source.domain

	constructor(
		private activatedRoute: ActivatedRoute
	){
		this.activatedRoute.data.subscribe(response => {
			this.type = response?.type
		})

		if (environment.terms) {
			this.website_url = environment.terms.website_url
			this.website = environment.terms.website
			this.email = environment.terms.website_email
			this.name = environment.terms.website_name
		}
	}
}
