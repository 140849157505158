<div class="gw-chkout-form">
  <div class="gw-chkout-form__shipping_address">
    <mat-form-field class="gw-chkout-field gw-chkout-field--half"
      [hideRequiredMarker]="true">
      <mat-label> {{ label ? label : type === 'shipping' ? 'Enter Shipping Address': 'Enter Billing Address' }} </mat-label>
      <input matInput
        [formControl]="addressControl.get('address_line')"
        (blur)="onBlur()"
        #shippingAddressInput>
        @if (addressControl.get('address_line').hasError('required')) {
          <mat-error>
            Please enter a valid address.
          </mat-error>
        } @else if (addressControl.get('address_line').hasError('selected')) {
          <mat-error>
            Please choose an address from the list
          </mat-error>
        }
    </mat-form-field>
    @if (predictions.length > 0 && showPredictions) {
      <div class="gw-chkout-prediction-container">
        <mat-list>
          @for (prediction of predictions; track prediction) {
            <mat-list-item (click)="selectPrediction(prediction)"
              (touchstart)="selectPrediction(prediction)">
              <div class="gw-chkout-prediction-container__list">
                <mat-icon svgIcon="location" class="gw-chkout-location__on"></mat-icon>
                <span [innerHTML]="highlightMatch(prediction.description, addressControl.get('address_line').value)" class="gw-chkout-prediction-container__item"></span>
              </div>
            </mat-list-item>
            @if (!$last) {
              <mat-divider></mat-divider>
            }
          }
        </mat-list>
        <div class="gw-chkout-prediction__google">
          <p class="gw-chkout-prediction__google-text">powered by </p>
          <img class="gw-chkout-prediction__google-img" src="/assets/icons/google_on_white.png" alt="Google Icon">
        </div>
      </div>
    }
  </div>

  @if (showAddress2) {
    <mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-ship__apt">
      <mat-label>Apt/Ste</mat-label>
      <input matInput 
        [formControl]="addressControl.get('address').get('address_2')">
      <mat-hint>
        Optional
      </mat-hint>
    </mat-form-field>
  }

</div>